import React from "react";
import { database } from "../../firebase";

function usePlans() {
  const [plans, setPlans] = React.useState<any | null>({});

  React.useEffect(() => {
    const planMap: any = {};
    database
      .collection("plans")
      .orderBy("order")
      .get()
      .then((querySnapshot: any) => {
        querySnapshot.docs.forEach((doc: any) => {
          const data: any = doc.data();
          const planData: any = {
            id: doc.id || "",
            type: "plan",
            name: data.name || "",
            headline: data.headline || "",
            description: data.description || "",
            isPublic: data.isPublic || false,
            isNav: data.isNav || false,
            order: data.order || 100,
            images: data.images || [],
          };
          planMap[doc.id] = planData;
        });
        setPlans(planMap);
      });
  }, []);

  return plans;
}

export default usePlans;
