import React from "react";
import { auth, database } from "../../firebase";

function useAuth() {
  const [authUser, setAuthUser] = React.useState<any | null>({
    authUser: null,
    loading: false,
    profile: null,
  });

  React.useEffect(() => {
    setAuthUser({ authUser: null, loading: true, profile: null });
    const unsubscribe = auth.onAuthStateChanged(async (user: any) => {
      if (user && user.emailVerified) {
        let isAdmin = false;
        await user.getIdTokenResult().then((idTokenResult: any) => {
          if (idTokenResult.claims.admin) {
            isAdmin = true;
          }
        });
        database
          .collection("users")
          .doc(user.uid)
          .get()
          .then((doc) => {
            let userData: any = doc.data();
            let profileData = {
              name: userData?.name || "",
              email: userData?.email || "",
              companyName: userData?.companyName || "",
              telnumber: userData?.telnumber || "",
              isAdmin: isAdmin,
              plan: userData?.plan || "",
              planType: userData?.planType || "",
              office: userData?.offices || "",
            };
            setAuthUser({
              authUser: user,
              loading: false,
              profile: profileData,
            });
          });
      } else {
        setAuthUser({ authUser: null, loading: false, profile: null });
      }
    });
    return () => unsubscribe();
  }, []);

  return authUser;
}

export default useAuth;
