import React from "react";
import { Backdrop, CircularProgress } from "@material-ui/core/";
import { css } from "@emotion/core";
/** @jsxImportSource @emotion/react */

const StyleBackdrop = css`
  z-index: 100;
`;

export const LoadingProgress: React.FC = () => {
  return (
    <Backdrop css={StyleBackdrop} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
