// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBw7OMnjM6K0QR8nxInkElumFnxpW5K51Y",
  authDomain: "venture-desk.firebaseapp.com",
  databaseURL: "https://venture-desk.firebaseio.com",
  projectId: "venture-desk",
  storageBucket: "venture-desk.appspot.com",
  messagingSenderId: "211944716297",
  appId: "1:211944716297:web:2258e2493932103571a0d8",
  measurementId: "G-MZH2SV9BXE",
};

export default firebaseConfig;
