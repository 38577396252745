import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";
import "firebase/functions";
import firebaseConfig from "./config";

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  // firebase.auth().useEmulator("http://localhost:5000");
}

const auth = firebase.auth();
const database = firebase.firestore();
const functions = firebase.functions();
const storage = firebase.storage();
// if (
//   typeof process.env !== "undefined" &&
//   process.env.NODE_ENV === "development"
// ) {
//   auth.useEmulator("http://localhost:9099");
//   database.settings({ host: "localhost:8080", ssl: false });
//   // database.settings({ ignoreUndefinedProperties: true });
//   functions.useFunctionsEmulator("http://localhost:5001");
// } else {
database.settings({ ignoreUndefinedProperties: true });
// }
export { auth, database, functions, storage };
