import React from "react";
import { database } from "../../firebase";

function useTimePlans() {
  const [plans, setPlans] = React.useState<any | null>({});

  React.useEffect(() => {
    const planMap: any = {};
    database
      .collection("timePlans")
      .orderBy("order")
      .get()
      .then((querySnapshot: any) => {
        querySnapshot.docs.forEach((doc: any) => {
          const data: any = doc.data();
          const planData: any = {
            id: doc.id || "",
            type: "timePlan",
            name: data.name || "",
            isPublic: data.isPublic || false,
            isNav: data.isNav || false,
            order: data.order || 100,
          };
          planMap[doc.id] = planData;
        });
        setPlans(planMap);
      });
  }, []);

  return plans;
}

export default useTimePlans;
