import React, { Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import { auth, database, storage, FirebaseContext } from "./firebase";
import useAuth from "./components/context/useAuth";
import usePlans from "./components/context/usePlans";
import useTimePlans from "./components/context/useTimePlans";
import { StylesProvider } from "@material-ui/core/styles";
import { LoadingProgress } from "./components/common/LoadingProgress";

const TopPage = lazy(() => import("./components/top/TopPage"));
const SignUpPage = lazy(() => import("./components/auth/signUp/SignUpPage"));
const SignUpDonePage = lazy(
  () => import("./components/auth/signUp/SignUpDonePage")
);
const ContactPage = lazy(() => import("./components/static/ContactPage"));
const ContactDonePage = lazy(
  () => import("./components/static/ContactDonePage")
);
const ContractFlowPage = lazy(
  () => import("./components/static/ContractFlowPage")
);
const FaqPage = lazy(() => import("./pages/FaqPage"));
const CompanyInfoPage = lazy(
  () => import("./components/static/CompanyInfoPage")
);
const PrivacyPolicyPage = lazy(() => import("./pages/PrivacyPolicy"));
const NewsPostDetailPage = lazy(
  () => import("./components/news/NewsPostDetailPage")
);
const LoginPage = lazy(() => import("./components/auth/login/LoginPage"));
const ForgetPasswordPage = lazy(
  () => import("./components/auth/login/forgetPasswordPage")
);
const ForgetPasswordDonePage = lazy(
  () => import("./components/auth/login/forgetPasswordDonePage")
);
const OfficeListPlanPage = lazy(
  () => import("./components/officeListPlan/OfiiceListPlanPage")
);
const OfficeDetailPage = lazy(
  () => import("./components/officeDetail/OfficeDetailPage")
);
const MeetingRoomListPage = lazy(
  () => import("./components/meetingRoomList/MeetingRoomListPage")
);
const MeetingRoomDetailPage = lazy(
  () => import("./components/meetingRoomDetail/MeetingRoomDetailPage")
);
const BookingFormDonePage = lazy(
  () => import("./components/meetingRoomDetail/BookingFormDonePage")
);
const BookingValidationPage = lazy(
  () => import("./pages/BookingValidationPage")
);
const BookingCancelPage = lazy(() => import("./pages/BookingCancelPage"));
const AdminPage = lazy(() => import("./components/admin/AdminPage"));
const PlanFormPage = lazy(() => import("./pages/PlanFormPage"));

function ScrollToTop() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const App: React.FC = () => {
  const user = useAuth();
  const plans = usePlans();
  const timePlans = useTimePlans();
  return (
    <StylesProvider injectFirst>
      <div className="App">
        <Router>
          <Suspense fallback={<LoadingProgress />}>
            <ScrollToTop />
            <Switch>
              <Route exact path="/dashboard">
                <AdminPage />
              </Route>
              <Route path="/">
                <FirebaseContext.Provider
                  value={{ user, plans, timePlans, auth, database, storage }}
                >
                  <Switch>
                    <Route exact path="/" component={TopPage} />
                    <Route exact path="/register" component={SignUpPage} />
                    <Route
                      exact
                      path="/register/done"
                      component={SignUpDonePage}
                    />
                    <Route exact path="/contact" component={ContactPage} />
                    <Route
                      exact
                      path="/contact/done"
                      component={ContactDonePage}
                    />
                    <Route exact path="/flow" component={ContractFlowPage} />
                    <Route exact path="/faq" component={FaqPage} />
                    <Route exact path="/plan_form" component={PlanFormPage} />
                    <Route exact path="/company" component={CompanyInfoPage} />
                    <Route
                      exact
                      path="/privacy_policy"
                      component={PrivacyPolicyPage}
                    />
                    <Route
                      exact
                      path="/news/:news_id"
                      component={NewsPostDetailPage}
                    />
                    <Route exact path="/login" component={LoginPage} />
                    <Route
                      exact
                      path="/forget_password"
                      component={ForgetPasswordPage}
                    />
                    <Route
                      exact
                      path="/forget_password/done"
                      component={ForgetPasswordDonePage}
                    />
                    <Route
                      exact
                      path="/p_:plan_id"
                      component={OfficeListPlanPage}
                    />
                    <Route
                      exact
                      path="/pt_:timePlan_id"
                      component={OfficeListPlanPage}
                    />
                    <Route
                      exact
                      path="/o_:office_id"
                      component={OfficeDetailPage}
                    />
                    <Route
                      exact
                      path="/rooms"
                      component={MeetingRoomListPage}
                    />
                    <Route
                      exact
                      path="/rooms/:room_id"
                      component={MeetingRoomDetailPage}
                    />
                    <Route
                      exact
                      path="/rooms/:room_id/done"
                      component={BookingFormDonePage}
                    />
                    <Route
                      exact
                      path="/booking/confirm/:booking_id/"
                      component={BookingValidationPage}
                    />
                    <Route
                      exact
                      path="/booking/cancel/:booking_id/"
                      component={BookingCancelPage}
                    />
                  </Switch>
                </FirebaseContext.Provider>
              </Route>
            </Switch>
          </Suspense>
        </Router>
      </div>
    </StylesProvider>
  );
};

export default App;
